import React from 'react';
import {navigate} from 'gatsby';
import classNames from 'classnames';

import Intergrations from '../../components/homePageIntegration';
import Layout from '../../components/layout/layout';
import {PageHero} from '../../components/page-hero';
import Button from '../../components/button';

import Ecomerce from '../../img/soulitions/soulition-ecommerce-retail.png';
import hexagram from '../../img/services/ecommerce.png';
import dash from '../../img/Dash-Screen-for-Website.png';
import uptime from '../../img/services/uptime.png';
import standardMonitor from '../../img/soulitions/Standard-monitor.png';
import advancedImg from '../../img/soulitions/advanced.png';
import advancedMonitorImg from '../../img/soulitions/advanced-uptime-monitor.png';
import userExperiance from '../../img/services/User-expericence.png';
import mobile from '../../img/services/mobile.png';
import broken from '../../img/services/broken.png';
import seo from '../../img/services/seo.png';
import security from '../../img/services/security.png';

const services = [
  {
    title: null,
    text: (
      <>
        <p>
          First impressions in eCommerce matter. It’s the difference between a bounce or a lifelong customer and product
          ambassador.
        </p>
        <p>
          Every day your website can face an increasing range of threats. Server problems, slow landing pages, broken
          links, frustrating mobile experiences, embarrassing spelling mistakes, changing SEO rules, 3rd party services
          breaking, or security issues that put your business at risk.
        </p>
        <p>
          To make matters worse, these issues can linger unnoticed, wasting your ad-budget and costing your business
          lost sales.
        </p>
        <p>
          Hexometer helps you deliver a first class shopping experience by continuously monitoring what others can’t, so
          you can catch problems before they affect your customers.
        </p>
      </>
    ),
    image: Ecomerce,
    alt: 'Ecommerce',
  },
  {
    title: 'Monitor your entire website, not just the homepage',
    text: (
      <>
        <p>
          Go beyond traditional uptime monitoring. Hexometer provides 24/7 peace of mind by continuously monitoring your
          entire website for issues.
        </p>
        <p>
          Hexometer is like having your very own QA team, continuously monitoring your website across six core areas to
          catch downtime, slow pages, spelling or grammar mistakes, visual bugs across different device types, broken
          links, server or JS errors, SEO problems and security issues.
        </p>
        <p>
          To make matters worse, these issues can linger unnoticed, wasting your ad-budget and costing your business
          lost sales.
        </p>
        <p>
          Track your progress and get real-time notifications when issues are detected via email, Slack, Telegram,
          Discord & Trello.
        </p>
      </>
    ),
    image: hexagram,
    alt: 'Designed for eCommerce',
  },
  {
    title: null,
    text: (
      <div className="flex-align-center">
        <h5>Get started in minutes, not days</h5>
        <p>
          Hexometer works in the cloud and you can get started in minutes to get comprehensive, sitewide, peace of mind.
          No software to install, or code changes required.
        </p>
        <p>Sign up, enter your domain name and Hexometer starts monitoring your website 24/7.</p>
        <Button primary className="trial_btn" onClick={() => navigate('/pricing/')}>
          Click here to get started in minutes
        </Button>
      </div>
    ),
    image: dash,
    alt: 'AI sidekick to protect and grow eCommerce business',
  },
  {
    title: 'Synthetic monitoring to bulletproof your business',
    text: (
      <>
        <p>
          <b>Standard monitors - uptime monitoring for any URL</b>
        </p>
        <p>
          Every minute counts when ad campaigns are running. With Hexometer you can deploy standard availability
          monitors on all your key landing pages to catch downtime and track HTTP response times down to every 60
          seconds.
        </p>
        <p>
          Standard monitors are ideal to keep a close eye on important landing pages, email servers, CDN, DNS, SFTP,
          RADIUS, POP3, IMAP and 3rd party services for downtime.
        </p>
        <div style={{margin: 'auto', textAlign: 'center'}}>
          <img src={standardMonitor} alt={'standard monitor'} className="service-img" />
        </div>
      </>
    ),
    image: uptime,
    alt: 'Uptime and Error monitoring',
  },
  {
    title: null,
    text: (
      <div className={'mg-minus'}>
        <p>
          <b>
            Advanced uptime monitors with keyword support to catch <br /> page level errors
          </b>
        </p>
        <p>
          Traditional uptime monitoring relies on HTTP response codes to detect downtime. With keyword based uptime
          monitoring you can check for the presence or absence of keywords on page to validate your uptime checks. This
          is ideal to catch errors or caching issues that would otherwise not be detected.
        </p>
        <div style={{margin: 'auto', textAlign: 'center'}}>
          <img src={advancedImg} alt={'advanced'} className="service-img" />
        </div>
      </div>
    ),
    image: advancedMonitorImg,
    alt: 'advanced monitoring',
  },
  {
    title: (
      <span>
        User experience monitoring to catch embarrassing issues before <br /> they affect your brand
      </span>
    ),
    text: (
      <>
        <p>
          First impressions matter, a lot. If your visitors encounter spelling mistakes or visual bugs their first visit
          might be their last.
        </p>
        <p>
          Hexometer continuously monitors your website for UX issues including: Spelling, grammar as well as display
          rendering issues across different device types to ensure your pages display as intended.
        </p>
        <p>Troubleshoot UX issues by performing on-demand user experience and monitor your progress through time.</p>
      </>
    ),
    image: userExperiance,
    alt: 'user experience monitoring',
  },
  {
    title: (
      <span>
        Performance monitoring to spot slow loading pages before <br /> they affect your conversion rate
      </span>
    ),
    text: (
      <>
        <p>
          According to Google, If your pages take longer than a few seconds to load you’re potentially losing nine out
          of ten visitors, right out of the gate.
        </p>
        <p>Hexometer continuously monitors your website for performance issues across desktop and mobile devices.</p>
        <div>
          <small>- Check how pages display on up to 7 different device types</small>
        </div>
        <div>
          <small>- Analyze website performance on desktop and mobile devices</small>
        </div>
        <div>
          <small>- Monitor page load speed</small>
        </div>
        <div>
          <small>- Get actionable insights to fix performance issues</small>
        </div>
        <br />
        <p>
          Troubleshoot performance issues by performing on-demand performance audits featuring Google Web Vitals on any
          page and monitor your progress through time.
        </p>
      </>
    ),
    image: mobile,
    alt: 'Performance monitoring & optimisations',
  },
  {
    title: 'Health monitoring to detect deal breaking errors, broken links and missing images',
    text: (
      <>
        <p>
          Website updates gone wrong, server issues or 3rd party services experiencing problems are like kryptonite for
          your business. Causing pages, links, images or key functionality such as your shopping cart not to work.
        </p>
        <p>
          Hexometer continuously monitors your website for broken links, server errors, JS errors, missing images or
          problematic scripts that can sabotage your business.
        </p>
        <p>
          Troubleshoot issues by performing on-demand page audits to uncover JS errors, W3C issues, server errors.
          Monitor your progress through time and get detailed debugging information to pinpoint or fix problems.
        </p>
      </>
    ),
    image: broken,
    alt: 'Broken link, server error and JS bug monitoring',
  },
  {
    title: 'SEO monitoring to improve and protect your search engine traffic',
    text: (
      <>
        <p>
          Hexometer continuously monitors your website for SEO issues that can impact your search engine performance
          highlighting problems and opportunities.
        </p>
        <p>Hexometer keeps an eye on a wide range of factors including:</p>
        <div>
          <small>- Title and description meta tags</small>
        </div>
        <div>
          <small>- Duplicate content issues</small>
        </div>
        <div>
          <small>- Broken links</small>
        </div>
        <div>
          <small>- Robots.txt</small>
        </div>
        <div>
          <small>- Indexing issues</small>
        </div>
        <div>
          <small>- Header Structure</small>
        </div>
        <div>
          <small>- Sitemap issues</small>
        </div>
        <div>
          <small>- Open Graph optimizations</small>
        </div>
        <br />
        <p>Hexometer integrates with Google Search Console to provide page performance metrics and ranking metrics.</p>
        <p>
          Troubleshoot SEO issues by performing on-demand performance audits and monitor your progress through time.
        </p>
      </>
    ),
    image: seo,
    alt: 'SEO Optimisation',
  },
  {
    title: 'Security Monitoring to support your eCommerce business against cyber threats',
    text: (
      <>
        <p>
          Did you know that over thirty thousand websites are infected by some type of malware daily? Security issues
          can cause downtime, loss of reputation, blacklisted search rankings as well as a steep cost to fix and restore
          your website.
        </p>
        <p>
          Hexometer continually checks if your domain is blacklisted by the leading security authorities and keeps an
          eye on your security headers, SSL best practices, blacklisted IP’s as well as your homepage for malicious
          URLs.
        </p>
        <p>Troubleshoot security issues by performing on-demand audits and monitor your progress through time.</p>
      </>
    ),
    image: security,
    alt: 'Security monitoring',
  },
];

const EcommerceAndRetail: React.FC = (): JSX.Element => {
  return (
    <Layout>
      <PageHero
        title="Peace of mind for eCommerce and retail businesses"
        subtitle="Hexometer is your AI sidekick that works behind the scenes, monitoring your website and marketing 24/7"
      />
      <div className="ecommerce-and-retail">
        {services.map((item, index) => (
          <section
            key={item.image}
            className={classNames('services', {grey: index % 2 !== 0}, {'light-grey': index % 2 === 0})}
          >
            <div className="container">
              {item.title && (
                <h3 className="f_size_27 f_700 t_color3 l_height40 mt_20 mb_50 text-center">{item.title}</h3>
              )}
              <div
                className={classNames('row_services', {
                  'flow-reverse': index % 2 !== 0,
                })}
              >
                <div className="col-12 col-lg-6 p-0">{item.text}</div>
                <div className="col-12 col-lg-6 p-0 text-center">
                  <img src={item.image} alt={item.alt} className="service-img" />
                </div>
              </div>
            </div>
          </section>
        ))}
        <br />
        <br />
        <br />
        <Intergrations />
      </div>
    </Layout>
  );
};

export default EcommerceAndRetail;
